// import fonts
//@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed|Roboto+Slab');
// TODO minimize the fonts here
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,700|Roboto+Condensed:100,300,700|Roboto+Slab:100,300,700');


// colors
$light-shade: #F7F8F8;
//$light-accent: #BDB0AA;
$light-accent: #877269;
//$brand: #6C424F;
$brand: #6C4242;
$dark-accent: #837381;
//$dark-shade: #4B4456;
$dark-shade: #443E4E;


// reset Bulma color variables
$primary: $brand;
$primary-invert: $light-shade;
$dark: $dark-shade;
$link: $dark-accent;
$hr-background-color: $light-shade;
$body-background-color: $light-shade;
$text: $dark;


// typography
//@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,700|Roboto+Condensed:100,300,700|Roboto+Slab:100,300,700');
$family-sans-serif: 'Roboto', sans-serif;
$family-primary: 'Roboto Slab', sans-serif;

.content.content {
  h1,h2,h3,h4,h5,h6 {
    font-family: $family-sans-serif;
    font-weight: normal;
  }
}


// misc
$radius-small: 1px;
$radius: 1px;
$radius-large: 1px;


// notification padding (used in sermons)
$notification-padding: 1.25rem 1.5rem;


// reset the navbar breakpoint
$navbar-breakpoint: 769px;

// navbar items
$navbar-item-hover-color: white;
$navbar-item-hover-background-color: $light-accent;


// import Bulma
@import "bulma";


