@charset "utf-8";
@import "./variables";

  ///
 /// Utilities
///

.offscreen {
  position: fixed;
  left: -1000px;
}

.noisy,
.noisy--dark {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: url('../images/noise-c.png') repeat;
  pointer-events: none;
}

.noisy {
  opacity: 0.5;
}

.noisy--dark {
  opacity: 1;
}

.sixteen-by-nine {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56% !important;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}


  ///
 /// Override Bulma classes
///

.container {
  width: 90vw;
  max-width: 1152px;
}


  ///
 /// Generic
///

main {
  background-image: url('../images/pencil-church-sm.png');
	background-position: bottom right;
	background-size: 500px;
	background-repeat: no-repeat;
  min-height: 580px;
}

.link,
.content a:not(.button) {
  color: $text;
  text-decoration: none;
  position: relative;
  box-shadow: inset 0px -1px 0px 0px $light-accent;
  transition: all $speed $easing;
  border-bottom: 1px $light-accent solid;

  &:focus,
  &:active,
  &:visited {
    background-color: none;
  }

  &:focus {
    outline: 1px $light-accent dotted;
  }

  &:hover {
    text-decoration: none;
    color: white;
    background-color: $light-accent;
  }
  
}
// inverted bg/fg:
.content.has-background-dark.has-text-white a:not(.button) {
  color: white;
}


.content img {
  float: right;
  max-width: 60%;
  padding: 0 0 $size-5 $size-6;
  @media (max-width: $tablet) {
    & {
      max-width: 100%;
      padding-left: 0;
    }
  }
}


  ///
 /// Components
///



.sermons {}
.sermons__show-more {
  margin-top: 1rem;
}
.sermon__player {
  text-align: center;
}
.sermon audio {
  margin-top: 1rem;
  display: inline-block;
  &:focus {
    outline: none;
    border: none;
  }
}


.alert__wrapper.alert__wrapper {
  padding: 0.5rem;
  margin-bottom: 0;
  background-color: $danger;
  color: $white;
}


.blocks {}
.block__wrapper {
  position: relative;
  background-size: cover;
  background-position: center center;
  padding-bottom: 48% !important;

  &:after {
    @extend .noisy--dark;
    opacity: 0.3;
    transition: opacity $speed $easing;
  }

  &:hover:after {
    opacity: 0;
  }
}

.block.block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}


.bio.bio {
  margin-bottom: $size-2;
}
.bio__pic.bio__pic {
  width: 100%;
}


.logo {
  display: flex;
  justify-content: center;
}
.logo__link {
  &:hover {
    color: $dark !important;
  }
}
.logo__img {
  max-height: none !important;
  height: 2.625em;
}
.logo__text {
  font-family: 'Roboto Slab', serif;
  padding-left: 0.25em;
  text-align: right;
  line-height: 1;
}
.logo__text--top {
  display: block;
  font-weight: 700;
  margin-bottom: 0.0875em;
  letter-spacing: 0.01em;
}
.logo__text--bottom {
  display: block;
  margin-bottom: 0.1875em;
  line-height: 0.6;
  letter-spacing: -0.05em;
}
.logo__text--est {
  display: block;
  font-size: 0.68em;
  letter-spacing: 0;
}


.navbar {
	font-family: 'Roboto Slab', sans-serif;
  background: none;
  z-index: initial;
}
.navbar-burger.navbar-burger {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  z-index: 1001;
  &.is-active {
    color: $light;
  }
}


.super-menu__wrapper {
  z-index: 1000;
  transition: opacity $speed $easing;
  opacity: 0;
  position: absolute;
  left: -10000px;
  width: 100vw;

  &.is-active {
    opacity: 1;
    left: 0;
  }
}
.super-menu {}
.super-menu__nav {
  position: relative;
  text-align: right;
  &:first-child {
    font-size: $size-5;
  }
}
.super-menu__info {
  position: relative;
  text-align: left;
  a {
    color: $white;
    &:hover {
      color: $light-accent;
    }
  }
  &:after {
    @extend .noisy;
  }
}

@media (max-width: $tablet) {
  .super-menu__nav,
  .super-menu__info {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .super-menu__nav {
    text-align: center;
  }
  .navbar-burger.navbar-burger.is-active {
    color: $dark;
  }
}


.hero {
  background-color: $dark;
  background-position: center;
  background-size: cover;
  position: relative;
  margin-bottom: -4rem;
  /*
  z-index: 0;
  */

  &:after {
    @extend .noisy;
  }
}
.hero__body {
  // above z-index of .noisy
  position: relative;
  z-index: 2;
  text-align: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.hero__text {
  display: inline-block;
  padding: 0.2em 0.7em;
}


.home-callout.home-callout {
  h1,h2,h3,h4,h5,h6 {
    color: $white;
  }
}


.footer {
  position: relative;
  padding-bottom: 3rem;

  a {
    color: $white;
    &:hover {
      color: $light-accent;
    }
  }
  &:after {
    @extend .noisy;
  }
}


.event__content {
  opacity: 0;
  display: none;
  position: absolute;
  top: 4%;
  left: 2%;
  width: 96%;
  z-index: 1;
  transition: opacity 0.25s ease;

  .event__close {
    position: absolute;
    top: $size-7;
    right: $size-7;
    z-index: -1;
  }

  .is-active & {
    display: block;
    opacity: 1;
  }
}
